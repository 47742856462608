/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-21 11:09:14
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-25 19:00:28
 */
import { axios } from '@/utils/request'

export const listareaCollectionStatistics = params => axios({
  url: '/api/firm/mhiac/mhiacArea/areaCollectionStatistics',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listfirmCollectionStatistics = params => axios({
  url: '/api/firm/mhiac/mhiacFirm/firmCollectionStatistics',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listDealerCollectionStatistics = params => axios({
  url: '/api/dealer/dealer/dealerStatics/firmCollectionStatistics',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})