<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-21 14:58:51
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-21 16:31:30
-->
<template>
  <div class="waterfall_plot">
    <div id="main" style="width:100%; height:400px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'waterfall_plot',
  data() {
    return {
      
    }
  },

  props: {
    option: {
      type: Object,
      default: {}
    }
  },

  computed: {},

  created() {
  },

  mounted() {
    this.getCharts()
  },

  methods: {
    getCharts() {
      let myChart = echarts.init(document.getElementById('main'))
      // 使用指定的配置项和数据显示图表。
      myChart.setOption(this.option)
    }
  },

}
</script>

<style lang='scss' scoped>

</style>