<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-21 16:45:08
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 18:03:56
-->
<template>
  <a-card :border="false">
    <div class="region_pay_back">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="事务所">
              <a-input v-model="searchData.name" placeholder="请输入事务所名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="{ fixed: true, type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :rowClassName="rowClassName"
      >
        <span slot="subtotal" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="january" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="february" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="march" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="april" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="may" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="june" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="july" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="august" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="october" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="december" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="december" slot-scope="text"> {{ text | formatMoney }} </span>
      </a-table>
      <EveryStatisCharts :id="'region_statis_charts'" :option="charts" ref="EveryStatisCharts"></EveryStatisCharts>
      <WaterfallPlot :id="'region_statis_charts'" :option="option" ref="WaterfallPlot"></WaterfallPlot>
    </div>
  </a-card>
</template>

<script>
import { FirmColumns } from './components/columsState.js'
import { listfirmCollectionStatistics } from './api/statisticsApi.js'
import EveryStatisCharts from './highcharts/EveryStatisCharts.vue'
import WaterfallPlot from './highcharts/WaterfallPlot.vue'

const title = {
  text: '各个事务所回款详情（万元）',
}
const xAxis = {
  categories: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
}
const legend = {
  layout: 'horizontal',
  verticalAlign: 'bottom',
  borderWidth: 0,
}

export default {
  name: 'region_pay_back',

  components: {
    EveryStatisCharts,
    WaterfallPlot,
  },

  data() {
    return {
      columns: FirmColumns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      chartList: [],
      charts: {
        series: [
          {
            type: 'spline',
            name: '回款情况',
            data: [],
          },
        ],
        xAxis: xAxis,
        yAxis: {
          title: {
            text: '',
          },
        },
        legend: legend,
        title: title,
        credits: {
          enabled: false,
        },
      },
      option: {
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            var tar = params[1]
            // console.log('params-----', params)
            return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          splitLine: { show: false },
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '',
            type: 'bar',
            stack: '总量',
            itemStyle: {
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)',
            },
            emphasis: {
              itemStyle: {
                barBorderColor: 'rgba(0,0,0,0)',
                color: 'rgba(0,0,0,0)',
              },
            },
            data: [],
          },
          {
            name: '回款',
            type: 'bar',
            stack: '总量',
            label: {
              show: true,
              position: 'inside',
            },
            barWidth: 30, //柱图宽度
            data: [],
          },
        ],
      },
      waterfallPlotList: [],
    }
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },

  created() {
    this.getData()
  },

  methods: {
    // 获取表格数据
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      listfirmCollectionStatistics({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total } = res.body
            this.tableData = res.body.records
            this.page.total = total
            this.getByFirmList(res.body.records[0].id)
            this.chartList = [
              this.tableData[0].january,
              this.tableData[0].february,
              this.tableData[0].march,
              this.tableData[0].april,
              this.tableData[0].may,
              this.tableData[0].june,
              this.tableData[0].july,
              this.tableData[0].august,
              this.tableData[0].september,
              this.tableData[0].october,
              this.tableData[0].november,
              this.tableData[0].december,
            ]
            this.$set(this.charts.series[0], 'data', this.chartList)
            this.$refs.EveryStatisCharts.getCharts()
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },

    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    // 更改分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    // 点击行选中
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    // 获取大区下事务所详情
    getByFirmList(id) {
      let firmId = this.selectedRowKeys.length <= 0 ? id : this.selectedRows[0].id
      this.axios.get(`/api/firm/mhiac/mhiacFirm/firmByFirmIdCollectionStatistics/${firmId}`).then((res) => {
        this.waterfallPlotList = res.body.firmByFirmIdCollectionStatistics.map((e) => e.dealerAmount)
        this.waterfallPlotList.unshift(Number(res.body.firmAmount))
        this.option.xAxis.data = res.body.firmByFirmIdCollectionStatistics.map((i) => i.dealerName)
        this.option.xAxis.data.unshift('总额')
        let dataList = this.waterfallPlotList.map((item, index, arr) => {
          let temp = 0
          let b = arr.slice(1).slice(0, index)
          temp = b.reduce((total, cur) => {
            return total + cur
          }, 0)
          return index === 0 ? 0 : arr[0] - temp
        })
        this.option.series[0].data = dataList
        this.option.series[1].data = this.waterfallPlotList
        this.option.title.text = `${
          this.selectedRows.length <= 0 ? this.tableData[0].title : this.selectedRows[0].title
        }回款统计（元）`
        this.$nextTick(() => {
          this.$refs.WaterfallPlot.getCharts()
        })
      })
    },

    // 当前选中数据
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.chartList = [
        this.selectedRows[0].january,
        this.selectedRows[0].february,
        this.selectedRows[0].march,
        this.selectedRows[0].april,
        this.selectedRows[0].may,
        this.selectedRows[0].june,
        this.selectedRows[0].july,
        this.selectedRows[0].august,
        this.selectedRows[0].september,
        this.selectedRows[0].october,
        this.selectedRows[0].november,
        this.selectedRows[0].december,
      ]
      this.$set(this.charts.series[0], 'data', this.chartList)
      this.$refs.EveryStatisCharts.getCharts()
      this.getByFirmList()
    },
  },
}
</script>

<style lang='scss' scoped>
</style>