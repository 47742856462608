/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-21 09:46:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 18:05:33
 */
export const columns = [
  {
    title: '大区名称',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align: 'center',
    fixed: 'left',
    ellipsis: true,

  },

  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'subtotal'}
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'january'}
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'february'}
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'march'}
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'april'}
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'may'}
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'june'}
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'july'}
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'august'}
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    // width:100,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'september'}
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'october',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'october'}
  },
  {
    title: '十一月',
    dataIndex: 'november',
    key: 'november',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'november'}
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'december'}
  },
]

export const FirmColumns = [
  {
    title: '事务所名称',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align: 'center',
    fixed: 'left',
    ellipsis: true,

  },

  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'subtotal'}
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'january'}
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'february'}
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'march'}
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'april'}
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'may'}
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'june'}
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'july'}
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'august'}
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    // width:100,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'september'}
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'october',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'october'}
  },
  {
    title: '十一月',
    dataIndex: 'november',
    key: 'november',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'november'}
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'december'}
  },
]

export const dealerColumns = [
  {
    title: '经销商名称',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align: 'center',
    fixed: 'left',
    // ellipsis: true,

  },

  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'subtotal'}
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'january'}
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'february'}
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'march'}
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'april'}
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'may'}
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'june'}
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'july'}
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'august'}
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    // width:100,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'september'}
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'october',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'october'}
  },
  {
    title: '十一月',
    dataIndex: 'november',
    key: 'november',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'november'}
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'december'}
  },
]
export const CusColumns = [
  {
    title: '经销商名称',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align: 'center',
    fixed: 'left',
    ellipsis: true,

  },

  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'subtotal'}
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'january'}
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'february'}
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'march'}
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'april'}
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'may'}
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'june'}
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'july'}
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'august'}
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    // width:100,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'september'}
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'october',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'october'}
  },
  {
    title: '十一月',
    dataIndex: 'november',
    key: 'november',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'november'}
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    width: 150,
    align: 'left',
    ellipsis: true,
    scopedSlots: {customRender: 'december'}
  },
]
/**
 * @returns 获取动态表格index
 */
export function formatSale(data) {
    let map = '';
      for (let i = 0; i <  data.length; i++) {
        const dataLength = data.length;
        map = dataLength
      }
    return map-1;
}
/**
 * @returns 获取动态表格指定元素出现次数
 */
export function formatAppoint(data) {
  const map = {};
    for(let i = 0;i<data.length; i++) {
      const key = data[i].channel
      if (map[key]) {
        map[key] += 1
      } else {
        map[key] = 1
      }
    }
  return map
}