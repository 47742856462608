import { render, staticRenderFns } from "./FirmReceivableStatistics.vue?vue&type=template&id=89617dcc&scoped=true&"
import script from "./FirmReceivableStatistics.vue?vue&type=script&lang=js&"
export * from "./FirmReceivableStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89617dcc",
  null
  
)

export default component.exports